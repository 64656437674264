import { createGlobalStyle } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

const GlobalStyle = createGlobalStyle`
  :root {
    --blue: #001E60;
    --beige: #F6F2ED;
    --white: #FFFFFF;
    --black: #1E1E1E;

  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;

    font-family: "GT Ultra";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(18)};
    letter-spacing: 0.1em;
    
    color: var(--blue);
    background: var(--beige);
    min-width: 320px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    ${mediaMax('mobile')} {
      font-size: 12px;
    line-height: 18px;
    }
  }
  body.hidden {
    height: 100vh !important;
  }
  body.hidden .home-wrapper {
    height: 100vh;
    overflow: hidden;
  }

  @-webkit-keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;

    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;

    }
  }
  @-webkit-keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;

    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;

    }
  }
  @keyframes fadeIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  .portal {
    position: fixed;
    inset: 0px;
    z-index: 106;
    display: flex;
    justify-content: center;
}
  .t2{
    font-family: "Garamond Premier Pro";
    font-size: ${calculateResponsiveSize(16)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(24)};
    letter-spacing: 0px;
    text-align: left;

  }
  .forMobile{
    display: none !important;
  }
  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .home-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  ${mediaMax('mobile')} {
    .forMobile{
      display: flex !important;
    }
    .forWeb{
      display: none !important;
    }
    .t2{
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;

    }
  }
`

export default GlobalStyle
